import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "",
        component: () => import("@/views/Main.vue"),
    },
    {
        path: "/loading",
        component: () => import("@/views/Loading.vue"),
    },
    {
        path: "/phone_number",
        component: () => import("@/views/PhoneNumber.vue"),
    },
    {
        path: "/sms_code",
        component: () => import("@/views/SmsCode.vue"),
    },
    {
        path: "/access_code",
        component: () => import("@/views/AccessCode.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


export default router;